<template>
  <div>
    <portal to="page-top-title">Api Providers</portal>

    <box-table title="List" url="/apis/providers" data-prop="providers" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Apis</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="provider in providers" :key="`providers-tr-${provider.id}`" :value="provider">
          <td>{{ provider.id }}</td>
          <td>{{ provider.name }}</td>
          <td>
            <badge title="Total">{{ provider.apis_count_total }}</badge>
            <badge title="Active" color="green">{{ provider.apis_count_active }}</badge>
            <badge title="Inactive" color="red">{{ provider.apis_count_inactive }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="provider.active"/>
          </td>
          <td class="text-right">{{ provider.created_at }}</td>
          <td class="text-right">{{ provider.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'apis-providers-edit', params: { id: provider.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'apis-providers-single', params: { id: provider.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'
import Btn from '@/views/components/simple/Btn'

export default {
  name: 'ApisProvidersList',
  metaInfo: {
    title: 'Apis | Providers'
  },
  components: {
    BoxTable,
    BadgeActive,
    Badge,
    Btn
  },
  data () {
    return {
      providers: []
    }
  }
}
</script>
